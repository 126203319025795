<template>
    <div class="pa-m2">
        <v-row no-gutters style="background-color: white;">
            <v-col cols="3" lg="3" md="4" sm="12" class="pr-2">
                <v-autocomplete
                    v-model="userToFilter"
                    flat
                    :items="users"
                    item-text="name"
                    item-value="name"
                    class="ml-2"
                    label="Search"
                    prefix="User :   "
                >
                </v-autocomplete>
            </v-col>
            <v-col cols="3" lg="3" md="4" sm="12" class="pl-2">
                <v-autocomplete
                    v-model="permissionToFilter"
                    flat
                    slolo
                    :items="headers"
                    item-text="text"
                    item-value="permissionString"
                    class="ml-2"
                    label="Search"
                    prefix="Permission :   "
                >
                </v-autocomplete>
            </v-col>
        </v-row>

        <table style="border-collapse: collapse;">
            <thead style="background-color: lightgray;">
                <tr>
                    <th
                        style="background-color: #eee;position: sticky; top: 0; z-index: 2;"
                    ></th>
                    <th
                        v-for="(title, i) in permissionsTitles"
                        :key="i"
                        :colspan="title.items"
                        class="px-2 pt-2"
                        :style="
                            `background-color: ${title.color}; position: sticky; top: 0; z-index: 2;`
                        "
                    >
                        {{ title.text }}
                    </th>
                </tr>
                <tr>
                    <td
                        style="background-color: #eee; position: sticky; top: 30px; z-index: 1;"
                    >
                        <span id="header-user">User</span>
                    </td>

                    <td
                        v-for="(header, j) in headers"
                        :key="j"
                        :style="
                            `background-color: ${header.color}; justify-items: center; align-items: center; position: sticky; top: 30px; z-index: 1;`
                        "
                    >
                        <span id="header">
                            {{ header.value }}
                        </span>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(user, k) in filteredUsers" :key="k">
                    <td id="info">
                        <span> {{ user.name }}</span>
                    </td>
                    <td v-for="(header, j) in headers" :key="j" id="info">
                        <v-icon
                            small
                            class="pa-2 d-flex justify-center align-center"
                            color="primary"
                            v-if="
                                user.permissions.includes(
                                    header.permissionString
                                )
                            "
                        >
                            mdi-check
                        </v-icon>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { storage } from '@/services/firebase'
import { mapMutations } from 'vuex'
import API from '@/services/api'
export default {
    name: 'UsersPermissions',
    data() {
        return {
            folderPictures: 'users_pictures',
            loading: false,
            height: 0,
            userToFilter: null,
            companyId: JSON.parse(localStorage.getItem('company')),
            folderLogo: 'company_logo',
            errorMsg: null,
            headers: [],
            users: [],
            permissionsTitles: [],
            permissionToFilter: null,
        }
    },
    computed: {
        filteredUsers() {
            let conditions = []
            if (this.userToFilter) {
                conditions.push(this.filterUser)
            }
            if (this.permissionToFilter) {
                conditions.push(this.filterPermission)
            }
            if (conditions.length > 0) {
                return this.users.filter(user => {
                    return conditions.every(condition => {
                        return condition(user)
                    })
                })
            }
            this.sort()
            return this.users
        },
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getUsers()
            this.users = users
            this.getSettings()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async getSettings() {
            try {
                const {
                    data: { settings },
                } = await API.getSettings()
                const indexLogo = settings.findIndex(x => x.name == 'Company')
                if (settings[indexLogo].generalInfo.image.file) {
                    const path = `${this.companyId}/${this.folderLogo}`
                    const storageRef = storage().ref(path)
                    const profilePicRef = storageRef.child(
                        settings[indexLogo].generalInfo.image.file
                    )
                    settings[
                        indexLogo
                    ].generalInfo.image.imageURL = await profilePicRef.getDownloadURL()
                }

                const permissionsSettings = settings.find(
                    x => x.name == 'Permissions'
                )

                let color = '#e6e6e6'
                Object.keys(permissionsSettings.permissions).forEach(key => {
                    const items = permissionsSettings.permissions[key].length

                    this.permissionsTitles.push({
                        text: key.toUpperCase(),
                        items,
                        color,
                    })
                    permissionsSettings.permissions[key].forEach(p => {
                        let text = p.name.substring(0, 15)
                        if (p.name.length > 15) {
                            text = text + '...'
                        }
                        this.headers.push({
                            text,
                            value: text,
                            permissionString: p.permission,
                            color,
                        })
                    })
                    color = color == '#e6e6e6' ? '#eee' : '#e6e6e6'
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        filterUser(user) {
            return user.name
                .toLowerCase()
                .includes(this.userToFilter.toLowerCase())
        },
        filterPermission(user) {
            return user.permissions.includes(this.permissionToFilter)
        },
        sort() {
            this.users.sort((a, b) => b.name - a.name)
        },
    },
}
</script>

<style scoped>
#header {
    display: inline-block;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    padding: 0px 5px;
}
#header-user {
    display: inline-block;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    padding: 0px 5px;
}
#info {
    border: 0.5px solid lightgrey !important;
    width: 120px;
    padding: 0px 10px;
}
</style>
